const env = {
  NETWORK_ID: 56,
  CHAIN_NAME: "BSC Main Network",
  URLS: {
    ETHERSCAN: "https://bscscan.com",
    API_SERVER: "https://vesting-api.gamefi.org",
  },
  NODE: {
    URL: "https://bsc-dataseed.binance.org/",
  },
  TOKEN: {
    ADDRESS: '0x89af13a10b32f1b2f8d1588f93027f69b6f4e27e'   //Gafi
  },
  TIMEOUT_DEADLINE: '2021/12/11 21:30:00',
  PRIVATE_CONTRACTS: [
    {
      address: '0x55b148b0C237B680BC50Ad75cA74499fa2117138',
      allow: [
        '*'
      ]
    }
  ]
};

export default env;
