const env = {
  NETWORK_ID: 97,
  CHAIN_NAME: "BSC Test Network",
  URLS: {
    ETHERSCAN: "https://testnet.bscscan.com",
    API_SERVER: "http://172.16.10.179:8080",
  },
  NODE: {
    URL: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  },
  TOKEN: {
    ADDRESS: '0xCc6aB3DDd713a612649B5A64C63F73e8255048d1'
  },
  TIMEOUT_DEADLINE: '2021/12/11 15:30:00',
  PRIVATE_CONTRACTS: [
    {
      address: '0x55b148b0C237B680BC50Ad75cA74499fa2117138',
      allow: ['*']
    }
  ]
};

export default env;
