import React, {useCallback, useEffect, useState} from 'react';
import {modalService} from 'src/app/components/commons/ModalListener';
import ImportModal from 'src/app/components/account/ImportModal';
import ClaimAllConfirmModal from 'src/app/components/claim/ClaimAllConfirmModal';
// import Lottie from 'react-lottie';
import metamaskLogo from 'src/assets/images/logos/metamask.svg';
import walletConnect from 'src/assets/images/logos/wallet-connect.svg';
import walletLink from 'src/assets/images/logos/wallet-link.svg';
import {formatAddress, formatBigNumber, displayFormattedNumber} from 'src/app/utils/helpers';
import {useDispatch, useSelector} from 'react-redux';
import {fetchClaimableAmount, fetchFirstUnlockAmount, fetchLockedAmount, fetchReleasedAmount, getFirstUnlockTxObject, getUnlockTxObject, fetchContractBalance, getFirstUnlockAllTxObject, getUnlockAllTxObject} from 'src/app/services/web3/Web3Service';
import {fetchContracts, fetchMerkleProof} from 'src/app/services/web3/apiService';
import {WALLET_TYPES} from 'src/app/configs/constants';
import {clearAccount} from 'src/app/actions/accountAction';
import ENV from 'src/app/configs/env';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {setContracts, setSelectedContract} from 'src/app/actions/globalAction';
import {ContractType} from 'src/app/types/tx-type';
import Countdown from '../countdown';
import BigNumber from "bignumber.js";

export default function Home() {
  const dispatch = useDispatch();

  const {address, wallet, type} = useSelector((state: any) => state.account);
  const {contracts, selectedContract} = useSelector((state: any) => state.global);

  const [isLoading, setIsLoading] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);
  const [claimableAmount, setClaimableAmount] = useState(0);
  const [claimedAmount, setClaimedAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isFirstUnlock, setIsFirstUnlock] = useState(true);
  const [proofData, setProofData] = useState();
  const [latestTxHash, setLatestTxHash] = useState('');
  const [isCountdown, setIsCountdown] = useState(false);

  const privateContracts = ENV.PRIVATE_CONTRACTS

  useEffect(() => {
    console.log('version 1.1.2')
  }, [])
  const setupContracts = useCallback(async () => {
    const result = await fetchContracts();

    if (result.length > 0) {
      if (!privateContracts?.length) {
        dispatch(setContracts(result))
        return
      }

      const listPrivateContracts = privateContracts.map((x: any) => x.address)
      const allowedContracts = result.filter((item: any) => !listPrivateContracts.includes(item.contract))

      privateContracts.forEach((contract: any) => {
        const listAllowed = contract.allow.map((item: any) => item.toLowerCase())
        if (listAllowed.includes(address?.toLowerCase()) || listAllowed.includes('*')) {
          allowedContracts.push(result.find((item: any) => item.contract === contract.address))
        }
      })

      dispatch(setContracts(allowedContracts))

      if (!selectedContract) {
        dispatch(setSelectedContract(allowedContracts[0]));
      }
    }
  }, [address, dispatch, privateContracts, selectedContract])

  useEffect(() => {
    if (ENV.TIMEOUT_DEADLINE && new Date(ENV.TIMEOUT_DEADLINE).getTime() > new Date().getTime()) {
      setIsCountdown(true)
      return
    }

    setupContracts();
  }, [setupContracts]);

  useEffect(() => {
    if (!address || !selectedContract) return;
    setupData(address, selectedContract);
  }, [address, selectedContract, dispatch]);

  function getFirstUnlockAmount(amount: any, startTime: number, endTime: number) {
    const now = new Date().getTime() / 1000
    if (!startTime || !endTime) {
      return amount
    }

    if (now >= endTime) {
      return amount
    }

    const result = new BigNumber(now - startTime).multipliedBy(new BigNumber(amount)).div(new BigNumber(endTime - startTime))
    return result || amount
  }

  async function setupData(address: string, contract: { contract: string; name: string; startTime: number; endTime: number }) {
    setIsLoading(true);

    try {
      const proofResult = await fetchMerkleProof(contract.name, address);
      const lockedAmount: number = await fetchLockedAmount(address, contract.contract);
      let total = proofResult.amount;
      let claimed, claimable;
      console.log(proofResult, lockedAmount)

      if (!proofResult.proof && !lockedAmount) {
        setTotalAmount(0);
        setClaimedAmount(0);
        setClaimableAmount(0);

        setTimeout(() => {
          setIsLoading(false);
        }, 1000);

        return;
      }

      if (lockedAmount === 0) {
        // const firstUnlockAmount: number = await fetchFirstUnlockAmount(proofResult.amount, contractAddress);
        setIsFirstUnlock(true)

        claimed = 0;
        claimable = getFirstUnlockAmount(proofResult.amount, contract.startTime, contract.endTime);
      } else {
        setIsFirstUnlock(false);

        if (!total) total = lockedAmount;
        claimed = await fetchReleasedAmount(address, contract.contract);
        claimable = await fetchClaimableAmount(address, contract.contract);
      }

      setTotalAmount(+formatBigNumber(total));
      setClaimedAmount(+formatBigNumber(claimed));
      setClaimableAmount(+formatBigNumber(claimable));
      setProofData(proofResult);
    } catch (e: any) {
      console.log(e.message);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }

  function openImportModal() {
    modalService.show(ImportModal);
  }

  function disconnect() {
    dispatch(clearAccount());
    setIsLoading(false);
  }

  async function claim() {
    if (isLoading) return;

    setIsClaiming(true);

    try {
      let claimTx;

      if (isFirstUnlock && proofData !== undefined) {
        // @ts-ignore
        claimTx = getFirstUnlockTxObject(address, proofData.index, address, proofData.amount, proofData.proof, selectedContract.contract);
        // @ts-ignore
      } else {
        claimTx = getUnlockTxObject(address, address, selectedContract ? selectedContract.contract : '');
      }

      const txHash = await wallet.makeTransaction(claimTx);
      setLatestTxHash(txHash);
    } catch (e: any) {
      console.log(e);
      alert(`Transaction failed: ${e?.message}`)
    }

    setIsClaiming(false);
  }

  function getWalletImage() {
    if (type === WALLET_TYPES.WALLET_CONNECT) {
      return walletConnect;
    } else if (type === WALLET_TYPES.WALLET_LINK) {
      return walletLink;
    } else {
      return metamaskLogo;
    }
  }

  function handleChangeContract(event: React.ChangeEvent<{value: any}>) {
    const contractAddr = event.target.value;
    const selectedContract = contracts.find((contract: ContractType) => {
      return contract.contract === contractAddr;
    });
    dispatch(setSelectedContract(selectedContract));
    if (!selectedContract || !address) return
    setupData(address, selectedContract)
  }

  function onTimeoout () {
    setIsCountdown(false)
  }
  return (
    <div className="home">
      <div className="home__title mb-2">GAFI Distribution Portal</div>
      {isCountdown ? (
        <div className="slide-up">
          <div style={{margin: '0px auto 35px', width: '220px', height: '220px'}}>
            <img width="100%" src={require('src/assets/images/icons/gamefi.png')} alt="gamefi" />
          </div>
          <div className="home__subtitle mb-2">Claim Your Vesting</div>
            <div className="home__desc">You can claim GAFI in public, private or seed round here.</div>
        </div>
      ) : !address && (
        <div className="slide-up">
          {/* <Lottie height={220} width={220} isClickToPauseDisabled={true} options={getAnimatedJsonOptions(step1Json)} style={{margin: '0px auto 35px'}} /> */}
          <div style={{margin: '0px auto 35px', width: '220px', height: '220px'}}>
            <img width="100%" src={require('src/assets/images/icons/gamefi.png')} alt="gamefi" />
          </div>
          <div className="home__subtitle mb-2">Claim Your Vesting</div>
          <div className="home__desc">Let us detect if you have any unclaimed GAFI in public, private or seed round by connecting to your wallet first.</div>
        </div>
      )}

      {isCountdown && (
        <Countdown deadline={ENV.TIMEOUT_DEADLINE} onTimeout={onTimeoout}></Countdown>
      )}

      {!isCountdown && address && (
        <div className="fade-in">
          <div className="home__address">
            <img style={{width: 16}} src={getWalletImage()} alt="wallet" />
            <span>{formatAddress(address, 8, -6)}</span>
          </div>
          <div className="home__disconnect" onClick={disconnect}>
            Disconnect
          </div>

          <div className="mt-5" style={{fontSize: 12, fontWeight: 500}}>
            <div>Select your Vesting Round</div>
            <FormControl className="mt-2" style={{width: '80%', borderRadius: '10px', backgroundColor: '#fff'}}>
              <Select labelId="vesting-round" value={selectedContract.contract} onChange={handleChangeContract}>
                {contracts.map((contract: any, index: number) => {
                  return contract && 
                    <MenuItem value={contract?.contract} key={index}>
                      {contract?.name}
                    </MenuItem>
                })}
              </Select>
            </FormControl>
          </div>

          {latestTxHash !== '' && (
            <div className="mt-2">
              <div className="mb-2 fw-medium">Tx Hash:</div>
              <a className="home__link" href={`${ENV.URLS.ETHERSCAN}/tx/${latestTxHash}`} target="_blank" rel="noreferrer noopener">
                {formatAddress(latestTxHash, 10, -8)}
              </a>
            </div>
          )}

          {isLoading && (
            <div>
              {/* <Lottie height={150} width={220} style={{marginBottom: '50px'}} isClickToPauseDisabled={true} options={getAnimatedJsonOptions(loadingJson)} /> */}
              <div style={{margin: '0px auto 35px', width: '220px', height: '220px'}}>
                <img width="100%" src={require('src/assets/images/icons/gamefi.png')} alt="gamefi" />
              </div>
            </div>
          )}

          {!isLoading && (
            <div className="fade-in">
              <div className="mt-6 mb-2">
                <span className="fw-medium mb-1 mr-1">Available:</span>
                <b>{displayFormattedNumber(totalAmount - claimedAmount, 4)} GAFI</b>
              </div>
              <div className="mb-2">
                <span className="fw-medium mb-1 mr-1">Claimed:</span>
                <b>{displayFormattedNumber(claimedAmount, 4)} GAFI</b>
              </div>
              <div className="mb-7">
                <span className="fw-medium mb-1 mr-1">Claimable:</span>
                <b>{displayFormattedNumber(claimableAmount, 4)} GAFI</b>
              </div>
            </div>
          )}
        </div>
      )}

      {
        !isCountdown ? (
          address ? <>
            {(isClaiming || isLoading) && 
              <div className="btn btn--gradient disabled" onClick={claim}>
                Loading...
              </div>
            }
            {!isClaiming && !isLoading &&
              <>
                <div className={`btn btn--gradient ${!claimableAmount ? 'disabled' : ''}`} onClick={claim}>
                  Claim
                </div>
              </>
            }
          </> : <div className={`btn btn--gradient ${isLoading || isClaiming ? 'disabled' : ''}`} onClick={openImportModal}>
            {(isClaiming || isLoading) && <div>Loading...</div>}
            {!isClaiming && !isLoading && <div>Connect Wallet</div>}
          </div>
        ) : <></>
      }
    </div>
  );
}
