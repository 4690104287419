import React, { useEffect, useState } from 'react'
import 'src/assets/styles/index.scss'

export default function Countdown(props: any) {
  const { deadline, onTimeout } = props
  const [second, setSecond] = useState('0');
  const [minute, setMinute] = useState('0');
  const [hour, setHour] = useState('0');
  const [day, setDay] = useState('0');

  useEffect(() => {
    let countDownInterval = undefined as any;

    if (deadline && new Date(deadline) >= new Date()) {
      const second = 1000,
      minute = second * 60,
      hour = minute * 60,
      day = hour * 24;

      let countDown = new Date(deadline).getTime();
      countDownInterval = setInterval(function() {

        let now = new Date().getTime(), distance = countDown - now;

        if (distance >= 0) {
          const currentDay = Math.floor(distance / (day));
          const currentHour = Math.floor((distance % (day)) / (hour))
          const currentMinute = Math.floor((distance % (hour)) / (minute));
          const currentSecond = Math.floor((distance % (minute)) / second)

          setDay(currentDay < 10 ? `0${currentDay}`: `${currentDay}`);
          setHour(currentHour < 10 ? `0${currentHour}`: `${currentHour}`);
          setMinute(currentMinute < 10 ? `0${currentMinute}`: `${currentMinute}`);
          setSecond(currentSecond < 10 ? `0${currentSecond}`: `${currentSecond}`);
        }

        //do something later when date is reached
        if (distance <= 0 && countDownInterval) {
          clearInterval(countDownInterval);
          if(typeof onTimeout === 'function') {
            onTimeout();
          }
          // window.location.reload();
        }
        //seconds
      }, 0);
    } else {
      setSecond("00");
      setMinute("00");
      setHour("00");
      setDay("00");
    }

    return () => {
      clearInterval(countDownInterval);
    }
  }, [deadline, onTimeout]);

  return (
    <div className="countdown-wrapper">
      <div>
        <div className="item">{ day }</div>
        <p className="text-gray-400">Days</p>
      </div>
      <span className="divider">:</span>
      <div>
        <div className="item">{ hour }</div>
        <p className="text-gray-400">Hours</p>
      </div>
      <span className="divider">:</span>
      <div>
        <div className="item">{ minute }</div>
        <p className="text-gray-400">Minutes</p>
      </div>
      <span className="divider">:</span>
      <div>
        <div className="item">{ second }</div>
        <p className="text-gray-400">Seconds</p>
      </div>
    </div>
  )
}
